<template>
  <div class="no-content-instruction-container">
    <img v-if="char==='pj_panda'"
      src="../../../assets/images/pj_panda-pointing.png"
      class="pj-panda">
    <img v-else-if="char==='cleo'"
      src="../../../assets/images/avatar_cleo.png"
      class="cleo">
    <img v-else-if="char==='hop'"
      src="../../../assets/images/avatar_hop.png"
      class="hop">
    <img v-else-if="char==='lil_leo'"
      src="../../../assets/images/avatar_lil_leo.png"
      class="lil-leo">
    <img v-else-if="char==='spotty'"
      src="../../../assets/images/avatar_spotty.png"
      class="spotty">
    <img v-else-if="char==='zebby'"
      src="../../../assets/images/avatar_zebby.png"
      class="zebby">
      
    <div class="bubble animated pulse infinite">
      <slot></slot>
    </div>

    <div class="veggies">
      <img class="veg-1 animated infinite rubberBand slower"
           src="../../../assets/images/veg_left.png"
           alt="broccoli" />
      <img class="veg-2 animated infinite swing slower"
           src="../../../assets/images/veg_mid.png"
           alt="scallion" />
      <img class="veg-3 animated infinite bounce slower"
           src="../../../assets/images/veg_right.png"
           alt="bell-pepper" />
    </div>
  </div>
</template>

<script>
  export default {
    name : 'NoContent',
    data() {
      return {
        characters : [
          'pj_panda',
          'cleo',
          'hop',
          'lil_leo',
          'spotty',
          'zebby',
        ],
      }
    },
    computed : {
      char() {
        return this.characters[Math.floor(Math.random()*
          this.characters.length)];
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/common/no-content";
</style>
